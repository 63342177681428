import { createNotificationProcessor } from '~/server/notifications/base.notifications';

export const reviewNotifications = createNotificationProcessor({
  'new-review': {
    displayName: 'New reviews',
    category: 'Update',
    prepareMessage: async ({ details }) => {
      if (details.version === 2) {
        let message = `${details.username} reviewed ${details.modelName}`;
        if (details.imageCount) message += ` with ${details.imageCount} images`;
        if (details.rating) message += ` (${details.rating}/5)`;
        return {
          message,
          url: `/reviews/${details.reviewId}`,
        };
      }
      return {
        message: `${details.username} reviewed ${details.modelName}`,
        url: `/redirect?to=review&reviewId=${details.reviewId}`,
      };
    },
    prepareQuery: ({ lastSent, category }) => `
      WITH new_reviews AS (
        SELECT DISTINCT
          m."userId" "ownerId",
          jsonb_build_object(
            'version', 2,
            'modelId', r."modelId",
            'reviewId', r.id,
            'modelName', m.name,
            'username', u.username,
            'rating', r.rating,
            'imageCount', (
                SELECT COUNT(*)
                FROM "Image" i
                JOIN "ImageResource" ir ON ir."imageId" = i.id AND
                WHERE i."userId" = r."userId"
            )
          ) "details"
        FROM "ResourceReview" r
        JOIN "User" u ON r."userId" = u.id
        JOIN "Model" m ON m.id = r."modelId"
        WHERE
          m."userId" > 0 AND
          m."userId" != r."userId" AND
          r."createdAt" > '${lastSent}'
      )
      INSERT INTO "Notification"("id", "userId", "type", "details", "category")
      SELECT
        REPLACE(gen_random_uuid()::text, '-', ''),
        "ownerId" "userId",
        'new-review' "type",
        details,
        '${category}'::"NotificationCategory" "category"
      FROM new_reviews
      WHERE NOT EXISTS (SELECT 1 FROM "UserNotificationSettings" WHERE "userId" = "ownerId" AND type = 'new-review');`,
  },
});
