import {
  Group,
  MantineSize,
  Paper,
  SimpleGrid,
  Stack,
  Text,
  ThemeIcon,
} from '@mantine/core';
import { IconAlertOctagon, IconAward, IconBell } from '@tabler/icons-react';
import { useRouter } from 'next/router';
import { useEffect, useState, MouseEvent } from 'react';

import { DaysFromNow } from '~/components/Dates/DaysFromNow';
import { UserAvatar } from '~/components/UserAvatar/UserAvatar';
import { getNotificationMessage } from '~/server/notifications/utils.notifications';
import { NotificationGetAll } from '~/types/router';
import { QS } from '~/utils/qs';

export function NotificationList({
                                   items,
                                   textSize = 'sm',
                                   withDivider = false,
                                   truncate = true,
                                   onItemClick,
                                 }: Props) {
  const router = useRouter();

  const [notifications, setNotifications] = useState<
    (NotificationGetAll['items'][number] & { message: string | null })[]
  >([]);

  useEffect(() => {
    const fetchNotificationDetails = async () => {
      const fetchedNotifications = await Promise.all(
        items.map(async (notification) => {
          const details = await getNotificationMessage({
            type: notification.type,
            details: notification.details as MixedObject,
          });

          // Ensure details is always a valid MixedObject or handle undefined
          const validDetails = details || { /* default/fallback details object */ };

          return {
            ...notification,
            message: details?.message ?? null,
            details: validDetails,
          };
        })
      );

      // Filter notifications that have a valid message and details
      setNotifications(
        fetchedNotifications.filter((notif) => notif.message && notif.details)
      );
    };

    fetchNotificationDetails();
  }, [items]);

  const handleClick = (e: MouseEvent<HTMLAnchorElement>, url: string) => {
    e.preventDefault();
    if (!url) return;

    const toModal = url.includes('?dialog=');
    if (toModal) {
      const [pathname] = router.asPath.split('?');
      const [notificationPathname, query] = url.split('?');
      if (pathname !== notificationPathname) {
        router.push(notificationPathname).then(() =>
          router.push(
            { pathname: notificationPathname, query: QS.parse(query) as any }, // eslint-disable-line
            undefined,
            {
              shallow: true,
            }
          )
        );
      } else {
        router.push(url, undefined, { shallow: true });
      }
    } else {
      router.push(url);
    }
  };

  return (
    <Paper radius="md" withBorder>
      <SimpleGrid cols={1} spacing={0}>
        {notifications.map((notification, index) => {
          const systemNotification = notification.type === 'system-announcement';
          const milestoneNotification = notification.type.includes('milestone');

          return (
            <Text
              component="a"
              href={notification.details?.url ?? ''}
              key={notification.id}
              variant="text"
              onClick={(e) => handleClick(e, notification.details?.url ?? '')}
            >
              <Paper
                onClick={() =>
                  !notification.read ? onItemClick(notification) : undefined
                }
                sx={(theme) => ({
                  cursor: 'pointer',
                  borderTop:
                    withDivider && index > 0
                      ? `1px solid ${
                        theme.colorScheme === 'dark'
                          ? theme.colors.dark[5]
                          : theme.colors.gray[2]
                      }`
                      : undefined,
                  background: notification.read
                    ? theme.colorScheme === 'dark'
                      ? undefined
                      : theme.colors.gray[0]
                    : theme.colorScheme === 'dark'
                      ? theme.colors.dark[6]
                      : undefined,
                  padding: theme.spacing.sm,
                  paddingLeft: !notification.read
                    ? theme.spacing.sm - 3
                    : theme.spacing.sm,
                  ':hover': {
                    backgroundColor:
                      theme.colorScheme === 'dark'
                        ? theme.fn.lighten(theme.colors.dark[4], 0.05)
                        : theme.fn.darken(theme.colors.gray[0], 0.05),
                  },
                })}
              >
                <Group spacing="xl" position="apart" align="start" noWrap>
                  <Group spacing="md" align="start" noWrap>
                    {systemNotification ? (
                      <ThemeIcon variant="light" size="xl" radius="xl" color="red">
                        <IconAlertOctagon stroke={1.5} />
                      </ThemeIcon>
                    ) : milestoneNotification ? (
                      <ThemeIcon variant="light" size="xl" radius="xl" color="green">
                        <IconAward stroke={1.5} />
                      </ThemeIcon>
                    ) : notification.details.actor ? (
                      <UserAvatar user={notification.details.actor} size="md" />
                    ) : (
                      <ThemeIcon variant="light" size="xl" radius="xl" color="yellow">
                        <IconBell stroke={1.5} />
                      </ThemeIcon>
                    )}
                    <Stack spacing={0}>
                      <Text
                        size={textSize}
                        weight="bold"
                        lineClamp={truncate ? 3 : undefined}
                      >
                        {notification.message}
                      </Text>
                      <Group spacing={2} noWrap>
                        {notification.details?.content && (
                          <>
                            <Text size="xs" color="dimmed" lineClamp={1}>
                              {notification.details.content}
                            </Text>
                            ・
                          </>
                        )}
                        <Text
                          size="xs"
                          color="dimmed"
                          style={{ whiteSpace: 'nowrap' }}
                          span
                        >
                          <DaysFromNow date={notification.createdAt} />
                        </Text>
                      </Group>
                    </Stack>
                  </Group>
                </Group>
              </Paper>
            </Text>
          );
        })}
      </SimpleGrid>
    </Paper>
  );
}

type Props = {
  items: NotificationGetAll['items'];
  onItemClick: (notification: NotificationGetAll['items'][number]) => void;
  textSize?: MantineSize;
  withDivider?: boolean;
  truncate?: boolean;
};
