import { BadgeProps, ButtonProps } from '@mantine/core';
import { createContext, ReactNode, useContext } from 'react';
import { ReviewReactions } from '@prisma/client';

type ReactionSettingsState = {
  hideReactionCount?: boolean;
  buttonStyling?: (
    reaction: ReviewReactions | 'AddReaction' | 'AmorTip',
    hasReacted?: boolean
  ) => Omit<ButtonProps, 'onClick'> & BadgeProps;
};

const ReactionSettingsContext = createContext<ReactionSettingsState | null>(null);
export const useReactionSettingsContext = () => {
  const context = useContext(ReactionSettingsContext);
  return context ?? {};
};

export const ReactionSettingsProvider = ({
                                           children,
                                           settings,
                                         }: {
  children: ReactNode;
  settings: ReactionSettingsState;
}) => {
  return (
    <ReactionSettingsContext.Provider value={settings}>{children}</ReactionSettingsContext.Provider>
  );
};
