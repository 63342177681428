import React, { forwardRef } from 'react';
import { useFeatureFlags } from '~/providers/FeatureFlagsProvider';
import { AutocompleteItem, Badge, Center, Group, Stack, Text, ThemeIcon } from '@mantine/core';
import { MediaHash } from '~/components/ImageHash/ImageHash';
import { EdgeMedia } from '~/components/EdgeMedia/EdgeMedia';
import {
  IconBrush,
  IconDownload,
  IconHeart,
  IconMessageCircle2,
  IconPhotoOff,
} from '@tabler/icons-react';
import { Highlight } from 'react-instantsearch';
import { UserAvatar } from '~/components/UserAvatar/UserAvatar';
import { IconBadge } from '~/components/IconBadge/IconBadge';
import { abbreviateNumber } from '~/utils/number-helpers';
import {
  useSearchItemStyles,
  ViewMoreItem,
} from '~/components/AutocompleteSearch/renderItems/common';
import { StarRating } from '~/components/StartRating/StarRating';
import { truncate } from 'lodash-es';
import { constants } from '~/server/common/constants';
import { SearchIndexDataMap } from '~/components/Search/search.utils2';
import { ThumbsUpIcon } from '~/components/ThumbsIcon/ThumbsIcon';

export const ModelSearchItem = forwardRef<
  HTMLDivElement,
  AutocompleteItem & { hit: SearchIndexDataMap['models'][number] }
>(({ value, hit, ...props }, ref) => {
  const features = useFeatureFlags();
  const { classes, theme } = useSearchItemStyles();

  if (!hit) return <ViewMoreItem ref={ref} value={value} {...props} />;
  const coverImage = JSON.parse(hit.image_urls)[0];

  const { metrics } = hit;

  return (
    <Group ref={ref} {...props} key={hit.id} spacing="md" align="flex-start" noWrap>
      <Center
        sx={{
          width: 64,
          height: 64,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: theme.radius.sm,
        }}
      >
        {coverImage ? (
          <EdgeMedia
            src={coverImage}
            name={hit.name}
            alt={hit.description ?? ''}
            anim={false}
            width={450}
            style={{
              minWidth: '100%',
              minHeight: '100%',
              objectFit: 'cover',
              position: 'absolute',
              top: 0,
              left: 0,
            }}
          />
        ) : (
          <ThemeIcon variant="light" size={64} radius={0}>
            <IconPhotoOff size={32} />
          </ThemeIcon>
        )}
      </Center>
      <Stack spacing={4} sx={{ flex: '1 !important' }}>
        <Group spacing={8}>
          <Text>
            <Highlight attribute="name" hit={hit} classNames={classes} />
          </Text>
        </Group>
        {/*<Group spacing={8}>*/}
        {/*  {category && <Badge size="xs">{removeTrailingNumber(category.name)}</Badge>}*/}
        {/*</Group>*/}
        <Group spacing={4}>
          <IconBadge icon={<ThumbsUpIcon size={12} />}>
            {abbreviateNumber(metrics.thumbsUpCount)}
          </IconBadge>
          <IconBadge icon={<IconHeart size={12} stroke={2.5} />}>
            {abbreviateNumber(metrics.favoriteCount)}
          </IconBadge>
          <IconBadge icon={<IconMessageCircle2 size={12} stroke={2.5} />}>
            {abbreviateNumber(metrics.commentCount)}
          </IconBadge>
        </Group>
      </Stack>
    </Group>
  );
});

ModelSearchItem.displayName = 'ModelSearchItem';
