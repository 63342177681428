import { Button, Center, createStyles, Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { IconBan } from '@tabler/icons-react';
import { signOut } from 'next-auth/react';
import React from 'react';

import { AppFooter } from '~/components/AppLayout/AppFooter';
import { AppHeader, RenderSearchComponentProps } from '~/components/AppLayout/AppHeader';
import { ContainerProvider } from '~/components/ContainerProvider/ContainerProvider';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useFeatureFlags } from '~/providers/FeatureFlagsProvider';
import { NewsletterDialog } from '../NewsletterDialog/NewsletterDialog';
import { ScrollAreaMain } from '~/components/ScrollArea/ScrollAreaMain';
import Script from 'next/script';
import { env } from '~/env/client.mjs';

type AppLayoutProps = {
  innerLayout?: ({ children }: { children: React.ReactNode }) => React.ReactNode;
  withScrollArea?: boolean;
};

export function AppLayout({
  children,
  renderSearchComponent,
  innerLayout,
  withScrollArea = true,
}: {
  children: React.ReactNode;
  renderSearchComponent?: (opts: RenderSearchComponentProps) => React.ReactElement;
} & AppLayoutProps) {
  const InnerLayout: any = innerLayout;
  const { classes } = useStyles();
  const user = useCurrentUser();
  // TODO - move the bannedAt check to _app.tsx
  const isBanned = !!user?.bannedAt;
  const flags = useFeatureFlags();

  if (isBanned)
    return (
      <Center py="xl">
        <Stack align="center">
          <ThemeIcon size={128} radius={100} color="red">
            <IconBan size={80} />
          </ThemeIcon>
          <Title order={1} align="center">
            You have been banned
          </Title>
          <Text size="lg" align="center">
            This account has been banned and cannot access the site
          </Text>
          <Button onClick={() => signOut()}>Sign out</Button>
        </Stack>
      </Center>
    );

  const content = InnerLayout ? (
    <InnerLayout>{children}</InnerLayout>
  ) : withScrollArea ? (
    <ScrollAreaMain>{children}</ScrollAreaMain>
  ) : (
    children
  );

  return (
    <>
      <AppHeader fixed={false} renderSearchComponent={renderSearchComponent} />
      <div className={classes.wrapper}>
        <ContainerProvider containerName="main">
          <main className={classes.main}>
            {content}
            {/*<FloatingActionButton2 transition="slide-up" mounted={true}>*/}
            {/*  <AssistantButton />*/}
            {/*</FloatingActionButton2>*/}
          </main>
          <AppFooter fixed={false} />
        </ContainerProvider>
      </div>

      <NewsletterDialog />
      <Script src="//code.tidio.co/bzj9etpizus5rosfsnobsgxcj91qzvox.js" strategy="lazyOnload" />
    </>
  );
}

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flex: 1,
    overflow: 'hidden',
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    position: 'relative',
  },
  assistant: {
    position: 'absolute',
    // top: '100%',
    // left: '100%',
    bottom: theme.spacing.xs,
    right: theme.spacing.md,
    display: 'inline-block',
    zIndex: 20,
    width: 42,
  },
}));

export function setPageOptions(Component: (...args: any) => JSX.Element, options?: AppLayoutProps) {
  (Component as any).options = options;
}
