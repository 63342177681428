import { ContextModalProps, ModalsProvider } from '@mantine/modals';
import dynamic from 'next/dynamic';
import { openReportModal } from '~/components/Modals/ReportModal';
import { openResourceReviewEditModal } from '~/components/ResourceReview/EditResourceReviewModal';
import { openUnpublishModal } from '~/components/Modals/UnpublishModal';
import { openCategoryChangeModal } from '~/components/Modals/CategoryChangeModal';
import { openAddToCollectionModal } from '~/components/Collections/AddToCollectionModal';
import { openManageHomeBlocksModal } from '~/components/HomeBlocks/ManageHomeBlocksModal';
import { openBuyAmorModal } from '~/components/Modals/BuyAmorModal';
import { openSendTipModal } from '~/components/Modals/SendTipModal';
import { openStripeTransactionModal } from '~/components/Modals/StripeTransactionModal';
import { ModalProps } from '@mantine/core';
import { openUserProfileEditModal } from '~/components/Modals/UserProfileEditModal';
import { openManageClubPostModal } from '~/components/Modals/ManageClubPostModal';

const QuestionsInfoModal = dynamic(() => import('~/components/Questions/QuestionInfoModal'));
const ReportModal = dynamic(() => import('~/components/Modals/ReportModal'));
const ResourceReviewEdit = dynamic(
  () => import('~/components/ResourceReview/EditResourceReviewModal')
);
const UnpublishModal = dynamic(() => import('~/components/Modals/UnpublishModal'));
const CategoryChangeModal = dynamic(() => import('~/components/Modals/CategoryChangeModal'));
const AddToCollectionModal = dynamic(() => import('~/components/Collections/AddToCollectionModal'));
const ManageHomeBlocksModal = dynamic(
  () => import('~/components/HomeBlocks/ManageHomeBlocksModal')
);
const BuyAmorModal = dynamic(() => import('~/components/Modals/BuyAmorModal'));
const SendTipModal = dynamic(() => import('~/components/Modals/SendTipModal'));
const StripeTransactionModal = dynamic(() => import('~/components/Modals/StripeTransactionModal'));
const UserProfileEditModal = dynamic(() => import('~/components/Modals/UserProfileEditModal'));
const ManageClubPostModal = dynamic(() => import('~/components/Modals/ManageClubPostModal'));

const registry = {
  report: {
    Component: ReportModal,
    fn: openReportModal,
  },
  unpublishModel: {
    Component: UnpublishModal,
    fn: openUnpublishModal,
  },
  categoryChangeModel: {
    Component: CategoryChangeModal,
    fn: openCategoryChangeModal,
  },
  resourceReviewEdit: {
    Component: ResourceReviewEdit,
    fn: openResourceReviewEditModal,
  },
  addToCollection: {
    Component: AddToCollectionModal,
    fn: openAddToCollectionModal,
  },
  manageHomeBlocks: {
    Component: ManageHomeBlocksModal,
    fn: openManageHomeBlocksModal,
  },
  buyAmor: {
    Component: BuyAmorModal,
    fn: openBuyAmorModal,
  },
  sendTip: {
    Component: SendTipModal,
    fn: openSendTipModal,
  },
  stripeTransaction: {
    Component: StripeTransactionModal,
    fn: openStripeTransactionModal,
  },
  userProfileEditModal: {
    Component: UserProfileEditModal,
    fn: openUserProfileEditModal,
  },
  manageClubPostModal: {
    Component: ManageClubPostModal,
    fn: openManageClubPostModal,
  },
};

export const CustomModalsProvider = ({ children }: { children: React.ReactNode }) => {
  // TODO.briant - fix the scrolling this was causing...
  // const handlePopState = () => {
  //   if (!location.href.includes('#')) {
  //     closeAllModals();
  //   }
  // };
  // useWindowEvent('popstate', handlePopState);

  return (
    <ModalsProvider
      labels={{
        confirm: 'Confirm',
        cancel: 'Cancel',
      }}
      modals={
        {
          questionsInfo: QuestionsInfoModal,
          ...(Object.keys(registry) as Array<keyof typeof registry>).reduce<any>((acc, key) => {
            acc[key] = registry[key].Component;
            return acc;
          }, {}),
        } as Record<string, React.FC<ContextModalProps<any>>> //eslint-disable-line
      }
      // Setting zIndex so confirm modals popup above everything else
      modalProps={{
        zIndex: 300,
      }}
    >
      {children}
    </ModalsProvider>
  );
};

export function openContext<TName extends keyof typeof registry>(
  modal: TName,
  props: Parameters<(typeof registry)[TName]['fn']>[0],
  modalProps?: Omit<ModalProps, 'opened' | 'onClose'>
) {
  registry[modal].fn(props as any, modalProps);
}
