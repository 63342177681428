import { useLovedByMenSessionContext } from '~/components/LovedByMenWrapped/LovedByMenSessionProvider';
import { postgresSlugify } from '~/utils/string-helpers';

export function useCurrentUser() {
  return useLovedByMenSessionContext();
}

export const useIsSameUser = (username?: string | string[]) => {
  const currentUser = useCurrentUser();
  if (!username || !currentUser) return false;
  return (
    !!currentUser &&
    postgresSlugify(currentUser?.username ?? '') === postgresSlugify(typeof username === 'string' ? username : username[0])
  );
};
