import { Group, GroupProps } from '@mantine/core';
import { PeriodFiltersDropdown } from '~/components/Image/Filters/PeriodFiltersDropdown';
import { SortFilter } from '../SortFilter';
import { useFeedFiltersStyles } from './FeedFilters.styles';
import { useFiltersContext } from '~/providers/FiltersProvider';

export function VideoFeedFilters({ ...groupProps }: GroupProps) {
  const { classes } = useFeedFiltersStyles();

  const { filters, setFilters } = useFiltersContext((state) => ({
    filters: state.videos,
    setFilters: state.setVideoFilters,
  }));

  return (
    <Group className={classes.filtersWrapper} spacing={8} noWrap {...groupProps}>
      <SortFilter
        type="videos"
        variant="button"
        buttonProps={{
          className: classes.subnavDropdown,
        }}
      />
      <PeriodFiltersDropdown
        feedType="videos"
        size="sm"
        w="100%"
        filters={filters}
        setFilters={setFilters}
        compact
        className={classes.subnavDropdown}
        isFeed
      />
    </Group>
  );
}
