import { useAmorSignalUpdate } from '~/components/Amor/useAmor';
import { useChatNewMessageSignal, useChatNewRoomSignal } from '~/components/Chat/ChatSignals';

export function SignalsRegistrar() {
  useAmorSignalUpdate();

  useChatNewMessageSignal();
  useChatNewRoomSignal();

  return null;
}
