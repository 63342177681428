import { FC, useEffect, useState } from 'react';
import { Affix, Button, Transition } from '@mantine/core';
import { IconArrowUp } from '@tabler/icons';
import { useDialogStore } from '~/components/Dialog/dialogStore';

interface CustomAffixProps {
  scrollContainer: React.RefObject<HTMLDivElement>;
}

const CustomAffix: FC<CustomAffixProps> = ({ scrollContainer }) => {
  const [showButton, setShowButton] = useState(false);
  const [showAffix, setShowAffix] = useState(true);
  const dialogs = useDialogStore((state) => state.dialogs);

  const handleScroll = () => {
    if (scrollContainer.current) {
      const scrollTop = scrollContainer.current.scrollTop;
      setShowButton(scrollTop > 0);
    }
  };

  useEffect(() => {
    const scrollElement = scrollContainer.current;
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
      return () => {
        scrollElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, [scrollContainer]);

  useEffect(() => {
    setShowAffix(dialogs.length === 0);
  }, [dialogs]);

  const scrollToTop = () => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <>
      {showAffix && (
        <Affix position={{ bottom: 20, right: 20 }} zIndex={2000}>
          <Transition transition="slide-up" mounted={showButton}>
            {(transitionStyles) => (
              <Button
                onClick={scrollToTop}
                style={{
                  ...transitionStyles,
                  borderRadius: '50%',
                  width: 40,
                  height: 40,
                  padding: 0,
                  zIndex: 1000,
                }}
                variant="filled"
                color="gold"
              >
                <IconArrowUp size={24} />
              </Button>
            )}
          </Transition>
        </Affix>
      )}
    </>
  );
};

export default CustomAffix;
