import { v4 as uuidv4 } from 'uuid';
import { preprocessFile } from '~/utils/media-preprocessors';
import { showErrorNotification } from '~/utils/notifications';
import { constants } from '~/server/common/constants';

export type DataFromFile = AsyncReturnType<typeof getDataFromFile>;
export const getDataFromFile = async (file: File) => {
  const processed = await preprocessFile(file);
  if (processed.type === 'video') {
    const { metadata } = processed;
    try {
      if (metadata.duration && metadata.duration > constants.mediaUpload.maxVideoDurationSeconds)
        throw new Error(
          `Video duration cannot be longer than ${constants.mediaUpload.maxVideoDurationSeconds} seconds. Please trim your video and try again.`,
        );
      if (
        metadata.width > constants.mediaUpload.maxVideoDimension ||
        metadata.height > constants.mediaUpload.maxVideoDimension
      )
        throw new Error(
          `Images cannot be larger than ${constants.mediaUpload.maxVideoDimension}px from either side. Please resize your image and try again.`,
        );
    } catch (error: any) {
      showErrorNotification({ error });
      return null;
    }
  }

  const { height, width, hash } = processed.metadata;

  return {
    file,
    uuid: uuidv4(),
    status: 'uploading' as TrackedFile['status'],
    message: 'Everything`s going fine',
    height,
    width,
    hash,
    ...processed,
    url: processed.objectUrl,
  };
};
