import { AmorTransactionDetails } from '~/server/schema/amor.schema';
import { slugit } from '~/utils/string-helpers';
import { PrismaClient } from '@prisma/client';

const prisma = new PrismaClient();

export const parseAmorTransactionDetails = async (
  details?: AmorTransactionDetails
): Promise<{
  url?: string | undefined;
  notification?: string | undefined;
  label?: string | undefined;
}> => {
  if (!details) {
    return {
      url: undefined,
      notification: undefined,
      label: undefined,
    };
  }

  const fallbackUrl = details.user && details.user !== 'a user' ? `/user/${details.user}` : '';
  const baseNotification = `You received a tip of ${details.amount} Amor from ${
    details.user ? `@${details.user}` : 'a user'
  }`;

  if (!details.entityId || !details.entityType) {
    return {
      url: fallbackUrl,
      notification: `${baseNotification}!`,
      label: 'User',
    };
  }

  const { entityId, entityType } = details;

  let entityTitle = '';
  if (entityType === 'Article') {
    entityTitle = await getEntityTitle(entityId);
  }

  const map: Record<string, { url: string; notification: string; label: string }> = {
    default: {
      url: fallbackUrl,
      notification: `${baseNotification}!`,
      label: 'User',
    },
    Image: {
      url: `/selfies/${entityId}`,
      notification: `${baseNotification} on one of your selfies!`,
      label: 'Image',
    },
    Article: {
      url: `/articles/${entityId}/${slugit(entityTitle)}`,
      notification: `${baseNotification} on one of your articles!`,
      label: 'Article',
    },
    Bounty: {
      url: `/bounties/${entityId}`,
      label: 'Bounty',
      notification: '', // Bounties won't be used for notifications thus far.
    },
  };

  return map[entityType] ?? map.default;
};

async function getEntityTitle(entityId: number): Promise<string> {
  try {
    const article = await prisma.article.findUnique({
      where: { id: entityId },
      select: { title: true },
    });

    if (article) {
      return article.title;
    } else {
      return '';
    }
  } catch (error) {
    console.error('Error fetching article title:', error);
    return ''; // You can choose to return null or throw an error based on your use case
  }
}
