import { Group, GroupProps } from '@mantine/core';
import { useFeedFiltersStyles } from '~/components/Filters/FeedFilters/FeedFilters.styles';
import { MediaFiltersDropdown } from '~/components/Image/Filters/MediaFiltersDropdown';
import { useImageFilters } from '~/components/Image/image.utils';
import { env } from '~/env/client.mjs';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { SortFilter } from '../SortFilter';
import { PeriodFiltersDropdown } from '~/components/Image/Filters/PeriodFiltersDropdown';
import { useFiltersContext } from '~/providers/FiltersProvider';

export function ImageFeedFilters({ ...groupProps }: GroupProps) {
  const { classes, theme } = useFeedFiltersStyles();
  const { hidden } = useImageFilters('images');

  const canToggleView = env.NEXT_PUBLIC_UI_CATEGORY_VIEWS && !hidden;
  const currentUser = useCurrentUser();
  const canViewNewest = currentUser?.isAdult ?? false;

  const { filters, setFilters } = useFiltersContext((state) => ({
    filters: state.images,
    setFilters: state.setImageFilters,
  }));

  return (
    <Group className={classes.filtersWrapper} spacing={8} noWrap {...groupProps}>
      <SortFilter
        type="images"
        variant="button"
        buttonProps={{
          className: classes.subnavDropdown,
        }}
      />
      <PeriodFiltersDropdown
        feedType="images"
        size="sm"
        w="100%"
        compact
        filters={filters}
        setFilters={setFilters}
        className={classes.subnavDropdown}
        isFeed
      />
      {/*{canToggleView && (*/}
      {/*  <ViewToggle*/}
      {/*    type="images"*/}
      {/*    color="gray"*/}
      {/*    radius="xl"*/}
      {/*    size="sm"*/}
      {/*    iconSize={16}*/}
      {/*    variant={theme.colorScheme === 'dark' ? 'filled' : 'light'}*/}
      {/*  />*/}
      {/*)}*/}
    </Group>
  );
}
