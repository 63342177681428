import { createNotificationProcessor } from '~/server/notifications/base.notifications';

export const creatorsProgramNotifications = createNotificationProcessor({
  'selfists-program-enabled': {
    displayName: 'Selfists program enabled',
    category: 'Amor',
    toggleable: false,
    prepareMessage: async () => ({
      message: `Your account has been approved for the LovedByMen Selfists Program! Setup your stripe account so you can start getting paid!.`,
      url: `/user/account#stripe`,
    }),
  },
  'selfists-program-payments-enabled': {
    displayName: 'Payments enabled',
    category: 'Amor',
    toggleable: false,
    prepareMessage: async () => ({
      message: `Your stripe account has been verified and approved for payments! You can now start earning money from your content!`,
      url: `/selfists-program`,
    }),
  },
  'selfists-program-rejected-stripe': {
    displayName: 'Selfists program Rejected (Stripe)',
    category: 'Amor',
    toggleable: false,
    prepareMessage: async () => ({
      message: `We're sorry, but it looks like your stripe account has been rejected for payments. If you need more information, you can contact support.`,
      url: `/selfists-program`,
    }),
  },
  'selfists-program-withdrawal-approved': {
    displayName: 'Selfists program - Withdrawal Approved',
    category: 'Amor',
    toggleable: false,
    prepareMessage: async () => ({
      message: `Your withdrawal request has been approved. Your funds will be transferred to your stripe account soon`,
      url: `/user/amor-dashboard#amor-withdrawals`,
    }),
  },
  'selfists-program-withdrawal-transferred': {
    displayName: 'Selfists program - Money transferred',
    category: 'Amor',
    toggleable: false,
    prepareMessage: async () => ({
      message: `Your request has been processed and money has been transfered to your stripe account.`,
      url: `/user/amor-dashboard#amor-withdrawals`,
    }),
  },
  'selfists-program-withdrawal-rejected': {
    displayName: 'Selfists program - Withdrawal Rejected',
    category: 'Amor',
    toggleable: false,
    prepareMessage: async () => ({
      message: `Moderators have rejected your withdrawal request. Please contact us for more information.`,
      url: `/user/amor-dashboard#amor-withdrawals`,
    }),
  },
  'selfists-program-withdrawal-reverted': {
    displayName: 'Selfists program - Money reverted',
    category: 'Amor',
    toggleable: false,
    prepareMessage: async () => ({
      message: `We have decided to revert money that was transfered to your stripe account. Please contact us for more information on why we came to this desicion.`,
      url: `/user/amor-dashboard#amor-withdrawals`,
    }),
  },
});
