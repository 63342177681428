import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useAmor } from '~/components/Amor/useAmor';
import { openBuyAmorModal } from '~/components/Modals/BuyAmorModal';
import { useIsMobile } from '~/hooks/useIsMobile';
import { useFeatureFlags } from '~/providers/FeatureFlagsProvider';
import { CreateAmorSessionInput } from '~/server/schema/stripe.schema';
import { getClientStripe } from '~/utils/get-client-stripe';
import { showErrorNotification, showSuccessNotification } from '~/utils/notifications';
import { trpc } from '~/utils/trpc';
import { useTrackEvent } from '../TrackView/track.utils';

export const useQueryAmorPackages = ({ onPurchaseSuccess }: { onPurchaseSuccess?: () => void }) => {
  const router = useRouter();
  const [processing, setProcessing] = useState<boolean>(false);
  const queryUtils = trpc.useContext();

  const { data: packages = [], isLoading } = trpc.stripe.getAmorPackages.useQuery();

  const createAmorSessionMutation = trpc.stripe.createAmorSession.useMutation({
    onSuccess: async ({ url, sessionId }) => {
      if (url) await router.push(url);
      else {
        const stripe = await getClientStripe();
        await stripe.redirectToCheckout({ sessionId });
      }
    },
    onError: (error) => {
      showErrorNotification({
        title: 'Could not process purchase',
        error: new Error(error.message),
      });
    },
  });

  const { mutateAsync: completeStripeAmorPurchaseMutation } =
    trpc.amor.completeStripeAmorPurchase.useMutation({
      async onSuccess() {
        await queryUtils.amor.getAmorAccount.invalidate();
        setProcessing(false);
        showSuccessNotification({
          title: 'Transaction completed successfully!',
          message: 'Your Amor has been added to your account.',
        });
        onPurchaseSuccess?.();
      },
      onError(error) {
        showErrorNotification({
          title: 'There was an error while attempting to purchase amor. Please contact support.',
          error: new Error(error.message),
        });

        setProcessing(false);
      },
    });

  const createCheckoutSession = (data: CreateAmorSessionInput) => {
    return createAmorSessionMutation.mutateAsync(data);
  };

  return {
    packages,
    isLoading,
    createCheckoutSession,
    completeStripeAmorPurchaseMutation,
    processing,
    setProcessing,
  };
};

export const useAmorTransaction = (opts?: {
  message?: string | ((requiredBalance: number) => string);
  purchaseSuccessMessage?: (purchasedBalance: number) => React.ReactNode;
  performTransactionOnPurchase?: boolean;
}) => {
  const { message, purchaseSuccessMessage, performTransactionOnPurchase } = opts ?? {};

  const features = useFeatureFlags();
  const { balance } = useAmor();
  const isMobile = useIsMobile();

  const { trackAction } = useTrackEvent();

  const tipUserMutation = trpc.amor.tipUser.useMutation({
    onError(error) {
      showErrorNotification({
        title: 'Error tipping user',
        error: new Error(error.message),
      });
    },
  });
  const hasRequiredAmount = (amorAmount: number) => balance >= amorAmount;
  const conditionalPerformTransaction = (amorAmount: number, onPerformTransaction: () => void) => {
    if (!features.amor) return onPerformTransaction();

    const hasRequiredAmount = balance >= amorAmount;
    if (!hasRequiredAmount) {
      trackAction({ type: 'NotEnoughFunds', details: { amount: amorAmount } }).catch(
        () => undefined
      );

      openBuyAmorModal(
        {
          message: typeof message === 'function' ? message(amorAmount - balance) : message,
          minAmorAmount: amorAmount - balance,
          onPurchaseSuccess: performTransactionOnPurchase ? onPerformTransaction : undefined,
          purchaseSuccessMessage,
        },
        { fullScreen: isMobile }
      );

      return;
    }

    onPerformTransaction();
  };

  return {
    hasRequiredAmount,
    conditionalPerformTransaction,
    tipUserMutation,
  };
};
