import { Button, ButtonProps, Group, Text, Tooltip } from '@mantine/core';
import { IconHexagonPlus } from '@tabler/icons-react';
import React from 'react';
import { useRouter } from 'next/router';

export function GenerateButton({
  iconOnly,
  children,
  ...buttonProps
}: Props) {

  const router = useRouter();

  const onClickHandler = () => {
    router.push('/selfie_posts/create');
  };

  if (children)
    return React.cloneElement(children, {
      ...buttonProps,
      onClick: onClickHandler,
      style: { cursor: 'pointer' },
    });

  const button = (
    <Button
      variant="filled"
      sx={iconOnly ? { paddingRight: 0, paddingLeft: 0, width: 36 } : { flex: 1 }}
      onClick={onClickHandler}
      {...buttonProps}
    >
      {iconOnly ? (
        <IconHexagonPlus size={24} />
      ) : (
        <Group spacing={8} noWrap>
          <IconHexagonPlus size={20} />
          <Text inherit inline className="hide-mobile">
            Create
          </Text>
        </Group>
      )}
    </Button>
  );

  return iconOnly ? (
    <Tooltip label="Start Generating" withArrow>
      {button}
    </Tooltip>
  ) : (
    button
  );
}
type Props = Omit<ButtonProps, 'onClick' | 'children'> & {
  iconOnly?: boolean;
  children?: React.ReactElement;
};
