import { Badge, Group, Text } from '@mantine/core';
import { UserAmor } from '~/components/User/UserAmor';

export const AvailableAmorBadge = () => {
  return (
    <Badge
      radius="xl"
      variant="filled"
      h="auto"
      py={4}
      px={12}
      sx={(theme) => ({
        backgroundColor:
          theme.colorScheme === 'dark' ? theme.fn.rgba('#000', 0.31) : theme.colors.gray[0],
      })}
    >
      <Group spacing={4} noWrap>
        <Text size="xs" color="dimmed" transform="capitalize" weight={600}>
          Available Amor
        </Text>
        <UserAmor iconSize={16} textSize="sm" withTooltip />
      </Group>
    </Badge>
  );
};
