import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { SignalMessages } from '~/server/common/enums';
import { SignalNotifications } from '~/components/Signals/SignalsNotifications';
import { SignalsRegistrar } from '~/components/Signals/SignalsRegistrar';
import io, { Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io/dist/typed-events';
import { useSession } from 'next-auth/react';
import { env } from '~/env/client.mjs';

type SignalState = {
  connected: boolean;
  socket: Socket<DefaultEventsMap, DefaultEventsMap> | null;
};

const SignalContext = createContext<SignalState | null>(null);
export const useSignalContext = () => {
  const context = useContext(SignalContext);
  if (!context) throw new Error('SignalContext not in tree');
  return context;
};

// Add possible types to this data structure. Leave any for safeguarding.
type SignalCallback = (data: any) => void;

export const useSignalConnection = (message: SignalMessages, cb: SignalCallback) => {
  const { connected, socket } = useSignalContext();
  const cbRef = useRef(cb);
  // any updates to cb will be assigned to cbRef.current
  cbRef.current = cb;

  useEffect(() => {
    const callback = (args: any) => cbRef.current(args);

    if (connected && socket) {
      socket.on(message, callback);
    }

    return () => {
      socket?.off(message, callback);
    };
  }, [connected, socket, message]);
};

var isCalled = false;

function WSignalProvider({ children }: { children: React.ReactNode }) {
  const [connected, setConnected] = useState(false);
  const [socket, setSocket] = useState<Socket | null>(null);

  const session = useSession();
  const userId = session.data?.user?.id;

  const socketInitializer = async () => {
    if (isCalled) return;
    if (!isCalled) isCalled = true;

    const tmpSocket = io(env.NEXT_PUBLIC_SOCKET_SERVER_HOST); // Point to the socket server
    tmpSocket.on('connect', () => {
      console.log('connected');
      setConnected(true);
      tmpSocket.emit('login', userId);
    });

    tmpSocket.on('disconnect', () => {
      console.log('disconnected');
      setConnected(false);
    });

    setSocket(tmpSocket);
  };

  useEffect(() => {
    if (!socket && !connected) socketInitializer();
  }, [socket, connected]);

  return (
    <SignalContext.Provider
      value={{
        connected,
        socket,
      }}
    >
      <SignalNotifications />
      <SignalsRegistrar />
      {children}
    </SignalContext.Provider>
  );
}

export function SignalProvider({ children }: { children: React.ReactNode }) {
  return WSignalProvider({ children });
}
