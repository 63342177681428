import { forwardRef, useRef, useCallback } from 'react';
import { SubNav } from '~/components/AppLayout/SubNav';
import { ScrollArea, ScrollAreaProps } from '~/components/ScrollArea/ScrollArea';
import CustomAffix from '../CustomAffix/CustomAffix';

export const ScrollAreaMain = forwardRef<HTMLElement, ScrollAreaProps>(
  ({ children, ...props }, ref) => {
    const internalRef = useRef<HTMLDivElement | null>(null);

    // Ref callback to handle both internalRef and forwarded ref
    const setRef = useCallback(
      (node: HTMLDivElement | null) => {
        internalRef.current = node;
        if (ref) {
          if (typeof ref === 'function') {
            ref(node);
          } else if (ref) {
            (ref as React.MutableRefObject<HTMLDivElement | null>).current = node;
          }
        }
      },
      [ref]
    );

    return (
      <ScrollArea ref={setRef} pt={0} {...props}>
        <SubNav />
        {children}
        <CustomAffix scrollContainer={internalRef} />
      </ScrollArea>
    );
  }
);

ScrollAreaMain.displayName = 'ScrollAreaMain';
