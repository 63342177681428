import { Group, Stack } from '@mantine/core';
import { useRouter } from 'next/router';
import { useCardStyles } from '~/components/Cards/Cards.styles';
import { FeedCard } from '~/components/Cards/FeedCard';
import { EdgeMedia } from '~/components/EdgeMedia/EdgeMedia';
import { ImageGuard } from '~/components/ImageGuard/ImageGuard';
import { MediaHash } from '~/components/ImageHash/ImageHash';
import { Reactions } from '~/components/Reaction/Reactions';
import { DEFAULT_EDGE_IMAGE_WIDTH } from '~/server/common/constants';
import { ImageGetInfinite } from '~/types/router';
import { ImageSearchIndexRecord } from '~/server/search-index/images.search-index';
import { useFeatureFlags } from '~/providers/FeatureFlagsProvider';
import { RoutedDialogLink } from '~/components/Dialog/RoutedDialogProvider';
import { useImagesContext } from '~/components/Image/Providers/ImagesProvider';
import { CosmeticType } from '@prisma/client';
import { HolidayFrame } from '../Decorations/HolidayFrame';

export function UnroutedImageCard({ data }: Props) {
  const { classes: sharedClasses, cx } = useCardStyles({
    aspectRatio: data.width && data.height ? data.width / data.height : 1,
  });
  const router = useRouter();
  const features = useFeatureFlags();

  const cardDecoration = data.user.cosmetics?.find(
    ({ cosmetic }) => cosmetic.type === CosmeticType.ContentDecoration,
  ) as (typeof data.user.cosmetics)[number] & {
    data?: { lights?: number; upgradedLights?: number };
  };

  return (
    <HolidayFrame {...cardDecoration}>
      <FeedCard className={sharedClasses.link}>
        <div className={sharedClasses.root}>
          <ImageGuard
            images={[data]}
            render={(image) => (
              <ImageGuard.Content>
                {({ safe }) => {
                  // Small hack to prevent blurry landscape images
                  const originalAspectRatio =
                    image.width && image.height ? image.width / image.height : 1;

                  return (
                    <>
                      <Group
                        position="apart"
                        align="start"
                        spacing={4}
                        sx={{ height: '100%', overflow: 'hidden' }}
                        className={cx(sharedClasses.contentOverlay, sharedClasses.top)}
                      >
                        {/*<ImageGuard.ToggleImage className={sharedClasses.chip} position="static" />*/}
                        {safe && (
                          <Stack spacing="xs" ml="auto">
                            <ImageGuard.Report context="image" position="static" withinPortal />
                          </Stack>
                        )}
                      </Group>
                      {/*{safe ? (*/}
                      <EdgeMedia
                        src={image.url}
                        name={image.name ?? image.id.toString()}
                        alt={image.name ?? ''}
                        type={image.type}
                        width={
                          originalAspectRatio > 1
                            ? DEFAULT_EDGE_IMAGE_WIDTH * originalAspectRatio
                            : DEFAULT_EDGE_IMAGE_WIDTH
                        }
                        className={sharedClasses.image}
                        wrapperProps={{ style: { height: '100%', width: '100%' } }}
                        loading="lazy"
                        contain
                      />
                      {/*) : (*/}
                      {/*  <MediaHash {...image} />*/}
                      {/*)}*/}
                    </>
                  );
                }}
              </ImageGuard.Content>
            )}
          />
          <Stack
            className={cx(
              'footer',
              sharedClasses.contentOverlay,
              sharedClasses.bottom,
              sharedClasses.gradientOverlay,
            )}
            spacing="sm"
          >
            <Group spacing={4} position="apart">
              <Reactions
                className={sharedClasses.reactions}
                entityId={data.id}
                entityType="image"
                reactions={data.reactions}
                metrics={{
                  likeCount: data.stats?.likeCountAllTime,
                  dislikeCount: data.stats?.dislikeCountAllTime,
                  heartCount: data.stats?.heartCountAllTime,
                  laughCount: data.stats?.laughCountAllTime,
                  cryCount: data.stats?.cryCountAllTime,
                  tippedAmountCount: data.stats?.tippedAmountCountAllTime,
                }}
                targetUserId={data.user.id}
              />
            </Group>
          </Stack>
        </div>
      </FeedCard>
    </HolidayFrame>
  );
}

export function ImageCard({ data }: Props) {
  const context = useImagesContext();

  return (
    <RoutedDialogLink name="imageDetail" state={{ type: data.type, imageId: data.id, ...context }}>
      <UnroutedImageCard data={data} />
    </RoutedDialogLink>
  );
}

type Props = { data: ImageGetInfinite[number] | ImageSearchIndexRecord };
