import { Button, Group, Radio, Stack } from '@mantine/core';
import React, { useMemo, useState } from 'react';

import { createContextModal } from '~/components/Modals/utils/createContextModal';
import { showErrorNotification, showSuccessNotification } from '~/utils/notifications';
import { trpc } from '~/utils/trpc';
import { CategoryTags } from '../CategoryTags/CategoryTags';

const { openModal, Modal } = createContextModal<{ model: any }>({
  name: 'categoryChangeModel',
  title: 'Change category',
  Element: ({ context, props: { model } }) => {
    const queryUtils = trpc.useContext();
    const [categoryId, setCategoryId] = useState(0);

    const changeCategoryMutation = trpc.model.changeCategory.useMutation({
      onSuccess: async () => {
        await queryUtils.model.getById.invalidate({ id: model.id });
        await queryUtils.model.getAll.invalidate();
        context.close();
        showSuccessNotification({
          title: 'Success',
          message: 'Category was changed successfully',
        });
      },
      onError: (error) => {
        showErrorNotification({
          title: 'Failed to change category',
          error: new Error(error.message),
          reason: 'An unexpected error occurred. Please try again later.',
        });
      },
    });
    const changeCategory = () => {
      return changeCategoryMutation.mutate({ modelId: model.id, categoryId });
    };

    const loading = changeCategoryMutation.isLoading;

    return (
      <Stack>
        <CategoryTags redirectMode={false} onChange={(value: number) => setCategoryId(value)} />
        {categoryId !== 0 && (
          <>
            <Group position="right">
              <Button onClick={changeCategory} loading={loading}>
                Change
              </Button>
            </Group>
          </>
        )}
      </Stack>
    );
  },
});

export const openCategoryChangeModal = openModal;
export default Modal;
