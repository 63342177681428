import {
  BountyType,
  Currency,
  MetricTimeframe,
  ReviewReactions,
} from '@prisma/client';
import { ModelSort } from '~/server/common/enums';
import { IMAGE_MIME_TYPE } from '~/server/common/mime-types';
import { IconFlame, IconCurrencyDollar, TablerIconsProps } from '@tabler/icons-react';
import { MantineTheme } from '@mantine/core';
import {
  ArticleSort,
  CollectionSort,
  ImageSort,
  PostSort,
  QuestionSort,
} from './enums';

export const constants = {
  modelFilterDefaults: {
    sort: ModelSort.Newest,
    period: MetricTimeframe.AllTime,
  },
  questionFilterDefaults: {
    sort: QuestionSort.MostLiked,
    period: MetricTimeframe.AllTime,
    limit: 50,
  },
  purchasableRewards: {
    coverImageAspectRatio: 1 / 2,
    coverImageWidth: 180,
  },
  galleryFilterDefaults: {
    sort: ImageSort.Newest,
    period: MetricTimeframe.AllTime,
    limit: 50,
  },
  postFilterDefaults: {
    sort: PostSort.Newest,
    period: MetricTimeframe.AllTime,
    limit: 50,
  },
  articleFilterDefaults: {
    sort: ArticleSort.Newest,
    period: MetricTimeframe.AllTime,
    limit: 50,
  },
  collectionFilterDefaults: {
    sort: CollectionSort.Newest,
    limit: 50,
  },
  imageFormats: ['optimized', 'metadata'],
  tagFilterDefaults: {
    trendingTagsLimit: 20,
  },
  reportingFilterDefaults: {
    limit: 50,
  },
  modelFileOrder: {
    Model: 0,
    'Pruned Model': 1,
    'Training Data': 2,
    Config: 3,
    'Text Encoder': 4,
    VAE: 5,
    Negative: 6,
    Archive: 7,
  },
  cardSizes: {
    model: 320,
    image: 320,
    articles: 320,
    bounty: 320,
    club: 320,
  },
  cacheTime: {
    postCategories: 60 * 60 * 1,
  },
  timeCutOffs: {
    updatedModel: 2 * 60 * 60 * 1000,
  },
  imagePurchase: 50,
  samplers: [
    'Euler a',
    'Euler',
    'LMS',
    'Heun',
    'DPM2',
    'DPM2 a',
    'DPM++ 2S a',
    'DPM++ 2M',
    'DPM++ SDE',
    'DPM++ 2M SDE',
    'DPM++ 3M SDE',
    'DPM fast',
    'DPM adaptive',
    'LMS Karras',
    'DPM2 Karras',
    'DPM2 a Karras',
    'DPM++ 2S a Karras',
    'DPM++ 2M Karras',
    'DPM++ SDE Karras',
    'DPM++ 2M SDE Karras',
    'DPM++ 3M SDE Karras',
    'DPM++ 3M SDE Exponential',
    'DDIM',
    'PLMS',
    'UniPC',
    'LCM',
  ],
  availableReactions: {
    [ReviewReactions.Heart]: '❤️',
    [ReviewReactions.Like]: '👍',
    [ReviewReactions.Dislike]: '👎',
    [ReviewReactions.Laugh]: '😂',
    [ReviewReactions.Cry]: '😢',
  },
  richTextEditor: {
    maxFileSize: 1024 * 1024 * 5, // 5MB
    accept: IMAGE_MIME_TYPE,
    // Taken from https://v5.mantine.dev/others/tiptap/#text-color
    presetColors: [
      '#25262b',
      '#868e96',
      '#fa5252',
      '#e64980',
      '#be4bdb',
      '#7950f2',
      '#4c6ef5',
      '#228be6',
      '#15aabf',
      '#12b886',
      '#40c057',
      '#82c91e',
      '#fab005',
      '#fd7e14',
    ] as string[],
  },
  imageGuard: {
    cutoff: 1000 * 60 * 60 * 24,
  },
  imageGeneration: {
    drawerZIndex: 301,
    requestBlocking: {
      warned: 3,
      notified: 5,
      muted: 8,
    },
    maxConcurrentRequests: 10,
  },
  tagVoting: {
    voteDuration: 1000 * 60 * 60 * 24,
    upvoteThreshold: 3,
  },
  imageTags: {
    styles: ['anime', 'cartoon', 'comics', 'manga'] as string[],
  },
  maxTrainingRetries: 2,
  mediaUpload: {
    maxImageFileSize: 50 * 1024 ** 2, // 50MB
    maxVideoDimension: 3840,
    maxVideoDurationSeconds: 1200,
  },
  bounties: {
    engagementTypes: ['active', 'favorite', 'tracking', 'supporter', 'awarded'],
    minCreateAmount: 500,
    maxCreateAmount: 100000000,
    supportedBountyToModels: [BountyType.PhotoCreation, BountyType.VideoCreation],
  },
  defaultCurrency: Currency.AMOR,
  referrals: {
    referralCodeMinLength: 6,
    referralCodeMaxCount: 3,
  },
  leaderboard: {
    legendScoring: {
      diamond: 10,
      gold: 8,
      silver: 6,
      bronze: 4,
    },
  },
  amor: {
    minChargeAmount: 50, // $5.00
    maxChargeAmount: 99999999, // $999,999.99
    cutoffDate: new Date('2023-10-17T00:00:00.000Z'),
    referralBonusAmount: 50,
    maxTipAmount: 10000000,
    minTipAmount: 5,
    maxEntityTip: 200,
    amorDollarRatio: 100,
    platformFeeRate: 2000, // 30.00%. Divide by 10000
    minAmorWithdrawal: 10000,
    maxAmorWithdrawal: 10000000,
  },
  profile: {
    coverImageAspectRatio: 1 / 4,
    mobileCoverImageAspectRatio: 1 / 4,
    coverImageHeight: 400,
    coverImageWidth: 1600,
    showcaseItemsLimit: 32,
    bioMaxLength: 400,
    messageMaxLength: 1200,
    locationMaxLength: 30,
  },
  clubs: {
    tierMaxMemberLimit: 9999,
    tierImageAspectRatio: 1 / 1,
    tierImageDisplayWidth: 124,
    tierImageSidebarDisplayWidth: 84,
    avatarDisplayWidth: 124,
    minMonthlyAmor: 5,
    minStripeCharge: 3000, // 3000 Amor = $3.00 USD
    headerImageAspectRatio: 1 / 4,
    postCoverImageAspectRatio: 1 / 4,
    engagementTypes: ['engaged'],
    coverImageHeight: 400,
    coverImageWidth: 1600,
  },
  comments: {
    imageMaxDepth: 3,
    bountyEntryMaxDepth: 3,
    maxDepth: 4,
  },
  altTruncateLength: 125,
  system: {
    user: { id: 1, username: 'admin' },
  },
  selfistsProgram: {
    rewards: {
      earlyAccessUniqueDownload: 10,
    },
  },
  cosmeticShop: {
    sectionImageAspectRatio: 250 / 1288,
    sectionImageHeight: 250,
    sectionImageWidth: 1288,
  },
  cosmetics: {
    frame: {
      padding: 6,
    },
  },
} as const;

export const POST_IMAGE_LIMIT = 20;

export const DEFAULT_EDGE_IMAGE_WIDTH = 450;

export type Sampler = (typeof constants.samplers)[number];

export const MODELS_SEARCH_INDEX = 'models_v1';
export const IMAGES_SEARCH_INDEX = 'images_v1';
export const ARTICLES_SEARCH_INDEX = 'articles_v1';
export const USERS_SEARCH_INDEX = 'users_v1';
export const COLLECTIONS_SEARCH_INDEX = 'collections';
export const BOUNTIES_SEARCH_INDEX = 'bounties';

export const CurrencyConfig: Record<
  Currency,
  {
    icon: (props: TablerIconsProps) => JSX.Element;
    color: (theme: MantineTheme) => string;
    fill?: (theme: MantineTheme) => string | string;
  }
> = {
  [Currency.AMOR]: {
    icon: IconFlame,
    color: (theme) => theme.colors.gold[9],
    fill: (theme) => theme.colors.gold[9],
  },
  [Currency.USD]: {
    icon: IconCurrencyDollar,
    color: (theme) => theme.colors.gold[9],
    fill: undefined,
  },
};

export const AMOR_FEATURE_LIST = [
  'Support your favorite selfists via tips and subscriptions',
  'Enjoy selfies from around the world!',
  'Create bounties to discover the best fashion!',
  'Purchase user cosmetics from our upcoming user cosmetic store!',
];

export const STRIPE_PROCESSING_AWAIT_TIME = 20000; // 20s
export const STRIPE_PROCESSING_CHECK_INTERVAL = 1000; // 1s

export const CacheTTL = {
  xs: 60 * 1,
  sm: 60 * 3,
  md: 60 * 10,
  lg: 60 * 30,
  hour: 60 * 60,
  day: 60 * 60 * 24,
  week: 60 * 60 * 24 * 7,
  month: 60 * 60 * 24 * 30,
} as const;

export const RECAPTCHA_ACTIONS = {
  STRIPE_TRANSACTION: 'STRIPE_TRANSACTION',
  COMPLETE_ONBOARDING: 'COMPLETE_ONBOARDING',
} as const;

export type RecaptchaAction = keyof typeof RECAPTCHA_ACTIONS;


export const selfistCardStats = ['followers', 'likes', 'uploads', 'downloads', 'reactions'];
export const selfistCardStatsDefaults = ['followers', 'likes'];
export const selfistCardMaxStats = 3;

export const milestoneNotificationFix = '2024-10-20';

export const creatorCardStats = [
  'followers',
  'likes',
  'reactions',
];
export const creatorCardStatsDefaults = ['followers', 'likes'];

