import { createNotificationProcessor } from '~/server/notifications/base.notifications';
import { parseAmorTransactionDetails } from '~/utils/amor';

export const amorNotifications = createNotificationProcessor({
  'tip-received': {
    displayName: 'Tip Received',
    category: 'Amor',
    prepareMessage: async ({ details }) => {
      const { url, notification } = await parseAmorTransactionDetails(details);
      return {
        message: `${notification}${details.message ? ` They said: "${details.message}".` : ''}`,
        url,
      };
    },
  },
});
