import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { env } from '~/env/client.mjs';

const useAnalytics = () => {
  useEffect(() => {
    const consent = localStorage.getItem('allowCookie');
    if (consent === 'accepted') {
      ReactGA.initialize(env.NEXT_PUBLIC_GGL_TAG);
      ReactGA.send('pageview');
    }
  }, []);
};

export default useAnalytics;
