import { createNotificationProcessor } from '~/server/notifications/base.notifications';
import { slugit } from '~/utils/string-helpers';

export const collectionNotifications = createNotificationProcessor({
  'contest-collection-item-status-change': {
    displayName: 'Your item has been reviewed',
    category: 'Update',
    prepareMessage: async ({ details }) => ({
      message: `The item you submitted to the contest "${details.collectionName}" has been ${details.status}.`,
      url: details.imageId
        ? `/selfies/${details.imageId}`
        : details.modelId
        ? `/models/${details.modelId}/${slugit(details.modelName)}`
        : details.articleId
        ? `/articles/${details.articleId}`
        : details.postId
        ? `/selfie_posts/${details.postId}`
        : `/collections/${details.collectionId}`,
    }),
  },
});
