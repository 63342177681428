import { trpc } from '~/utils/trpc';
import { useFeatureFlags } from '~/providers/FeatureFlagsProvider';
import { useCurrentUser } from '~/hooks/useCurrentUser';
import { useCallback } from 'react';
import { useSignalConnection } from '~/components/Signals/SignalsProvider';
import { SignalMessages } from '~/server/common/enums';
import { AmorUpdateSignalSchema } from '~/server/schema/signals.schema';
import { AmorAccountType } from '~/server/schema/amor.schema';
import { useSession } from 'next-auth/react';

export const useAmor = (accountId?: number, accountType?: AmorAccountType) => {
  const currentUser = useCurrentUser();
  const features = useFeatureFlags();
  const { data, isLoading } = trpc.amor.getAmorAccount.useQuery(
    {
      accountId: accountId ?? (currentUser?.id as number),
      accountType: accountType ?? 'User',
    },
    {
      enabled: !!currentUser && features.amor,
    }
  );

  return {
    balanceLoading: isLoading,
    balance: data?.balance ?? 0,
    lifetimeBalance: data?.lifetimeBalance ?? 0,
  };
};

export const useAmorSignalUpdate = () => {
  const queryUtils = trpc.useContext();
  const { data: session } = useSession();

  const onBalanceUpdate = useCallback(
    (updated: AmorUpdateSignalSchema) => {
      if (!session?.user) return;

      return queryUtils.amor.getAmorAccount.setData(
        {
          accountId: session.user.id as number,
          accountType: 'User',
        },
        (old) => {
          if (!old) return old;
          return { ...old, balance: updated.balance, lifetimeBalance: updated.lifetimeBalance };
        }
      );
    },
    [queryUtils, session]
  );

  useSignalConnection(SignalMessages.AmorUpdate, onBalanceUpdate);
};
