import { CloseButton, Divider, Group, Stack, Text } from '@mantine/core';

import { createContextModal } from '~/components/Modals/utils/createContextModal';
import { AmorPurchase } from '~/components/Amor/AmorPurchase';
import { useTrackEvent } from '../TrackView/track.utils';
import { AvailableAmorBadge } from '~/components/Amor/AvailableAmorBadge';

const { openModal, Modal } = createContextModal<{
  message?: string;
  purchaseSuccessMessage?: (purchasedBalance: number) => React.ReactNode;
  onPurchaseSuccess?: () => void;
  minAmorAmount?: number;
}>({
  name: 'buyAmor',
  withCloseButton: false,
  centered: true,
  size: 'lg',
  radius: 'lg',
  zIndex: 400,
  Element: ({
    context,
    props: { message, onPurchaseSuccess, minAmorAmount, purchaseSuccessMessage },
  }) => {
    const { trackAction } = useTrackEvent();
    const handleClose = () => {
      trackAction({ type: 'PurchaseFunds_Cancel', details: { step: 1 } }).catch(() => undefined);
      context.close();
    };

    return (
      <Stack spacing="md">
        <Group position="apart" noWrap>
          <Text size="lg" weight={700}>
            Buy Amor
          </Text>
          <Group spacing="sm" noWrap>
            <AvailableAmorBadge />
            <CloseButton radius="xl" iconSize={22} onClick={handleClose} />
          </Group>
        </Group>
        <Divider mx="-lg" />
        <AmorPurchase
          message={message}
          onPurchaseSuccess={() => {
            context.close();
            onPurchaseSuccess?.();
          }}
          minAmorAmount={minAmorAmount}
          purchaseSuccessMessage={purchaseSuccessMessage}
          onCancel={handleClose}
        />
      </Stack>
    );
  },
});

export const openBuyAmorModal = openModal;
export default Modal;
