import { Button, ButtonProps, Group, Text, Tooltip } from '@mantine/core';
import { useFeatureFlags } from '~/providers/FeatureFlagsProvider';
import React from 'react';
import { CurrencyBadge } from '~/components/Currency/CurrencyBadge';
import { Currency } from '@prisma/client';
import { IconAlertTriangleFilled } from '@tabler/icons-react';
import { useAmorTransaction } from './amor.utils';

type Props = ButtonProps & {
  amorAmount: number;
  message?: string | ((requiredBalance: number) => string);
  label: string;
  onPerformTransaction?: () => void;
  purchaseSuccessMessage?: (purchasedBalance: number) => React.ReactNode;
  performTransactionOnPurchase?: boolean;
};

export function AmorTransactionButton({
  amorAmount,
  onPerformTransaction,
  purchaseSuccessMessage,
  message = "You don't have enough funds. Buy or earn more amor to perform this action",
  performTransactionOnPurchase = true,
  label,
  ...buttonProps
}: Props) {
  const features = useFeatureFlags();
  const { conditionalPerformTransaction, hasRequiredAmount } = useAmorTransaction({
    message,
    purchaseSuccessMessage,
    performTransactionOnPurchase,
  });

  if (!features.amor) return null;

  const onClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (!onPerformTransaction) {
      return;
    }

    if (!features.amor) {
      // Just perform whatever it is we need
      onPerformTransaction();
      return;
    }

    conditionalPerformTransaction(amorAmount, onPerformTransaction);
  };
  const hasCost = amorAmount > 0;

  return (
    <Button
      color={hasCost ? 'yellow.7' : 'gold'}
      {...buttonProps}
      onClick={onPerformTransaction ? onClick : undefined}
    >
      <Group spacing="md" noWrap>
        {hasCost && (
          <CurrencyBadge
            currency={Currency.AMOR}
            unitAmount={amorAmount}
            displayCurrency={false}
            radius={buttonProps?.radius ?? 'sm'}
            px="xs"
          >
            {!hasRequiredAmount(amorAmount) && (
              <Tooltip
                label="Insufficient amor. Click to buy more"
                style={{ textTransform: 'capitalize' }}
                maw={250}
                multiline
                withArrow
              >
                <IconAlertTriangleFilled
                  color="red"
                  size={12}
                  fill="currentColor"
                  style={{ marginRight: 4 }}
                />
              </Tooltip>
            )}
          </CurrencyBadge>
        )}
        <Text>{label}</Text>
      </Group>
    </Button>
  );
}
