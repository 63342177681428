import { ActionIcon, Box, Button, createStyles, Group, ScrollArea } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import { useEffect, useRef, useState } from 'react';

import { SegmentedControl } from '@mantine/core';
import { trpc } from '~/utils/trpc';
import { containerQuery } from '~/utils/mantine-css-helpers';
import { useRouter } from 'next/router';
import { removeEmpty } from '~/utils/object-helpers';
import { removeTrailingNumber } from '~/utils/freq';

const useStyles = createStyles((theme) => ({
  tagsContainer: {
    position: 'relative',

    [containerQuery.largerThan('lg')]: {
      // marginLeft: theme.spacing.xl * -1.5, // -36px
      // marginRight: theme.spacing.xl * -1.5, // -36px
    },
  },
  tagsGroup: {
    [containerQuery.largerThan('lg')]: {
      // marginLeft: theme.spacing.xl * 1.5, // 36px
      // marginRight: theme.spacing.xl * 1.5, // 36px
    },
  },
  tag: {
    textTransform: 'capitalize',
  },
  title: {
    display: 'none',

    [containerQuery.largerThan('sm')]: {
      display: 'block',
    },
  },
  arrowButton: {
    '&:active': {
      transform: 'none',
    },
  },
  hidden: {
    display: 'none !important',
  },
  leftArrow: {
    display: 'none',
    position: 'absolute',
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    paddingRight: theme.spacing.xl,
    zIndex: 12,
    backgroundImage: theme.fn.gradient({
      from: theme.colorScheme === 'dark' ? theme.colors.dark[7] : 'white',
      to: 'transparent',
      deg: 90,
    }),

    [containerQuery.largerThan('md')]: {
      display: 'block',
    },
  },
  rightArrow: {
    display: 'none',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    paddingLeft: theme.spacing.xl,
    zIndex: 12,
    backgroundImage: theme.fn.gradient({
      from: theme.colorScheme === 'dark' ? theme.colors.dark[7] : 'white',
      to: 'transparent',
      deg: 270,
    }),

    [containerQuery.largerThan('md')]: {
      display: 'block',
    },
  },
  viewport: {
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  root: {
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.fn.lighten(theme.colors.dark[8], 0.05)
        : theme.fn.darken(theme.colors.gray[3], 0.01),
    boxShadow: 'var(--mantine-shadow-md)',
    border: 'rem(1px) solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-4))',
  },
  indicator: {
    backgroundImage: 'linear-gradient(to right,  red,  green)',
  },
  control: {
    '&::before': {
      display: 'none',
    },
    border: 'none !important',
  },
  label: {
    '&,&:hover': {
      '&[data-active]': {
        color: 'var(--mantine-color-white)',
      },
    },
  },
}));

type OnChangeType = (id: number) => void;

export function CategoryTags({
  selected,
  setSelected,
  filter,
  onChange,
  redirectMode = true,
}: {
  selected?: number;
  redirectMode?: boolean;
  setSelected?: (tag?: number) => void;
  filter?: (tag: string) => boolean;
  onChange?: OnChangeType;
}) {
  const { classes, cx, theme } = useStyles();

  const { query, pathname, replace } = useRouter();
  const [categoryId, setCategoryId] = useState<number>(0);

  const { data: { items: categories } = { items: [] } } = trpc.tag.getAll.useQuery({
    types: ['System'],
    unlisted: false,
    categories: true,
    limit: 200,
  });

  useEffect(() => {
    if (query.categoryId) {
      setCategoryId(parseInt(query.categoryId as string));
    }
  }, [query.categoryId]);

  useEffect(() => {
    if (selected !== undefined) {
      setCategoryId(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (
      categories.filter((x) => x.parentId === null).findIndex((x) => x.id === categoryId) === -1
    ) {
      const subCategory = categories
        .filter((x) => x.parentId !== null)
        .find((x) => x.id === categoryId);
      if (subCategory) {
        const parentId = subCategory?.parentId.toString();
        if (parentId !== parentCategory) setParentCategory(parentId);
        onChange && onChange(subCategory.id);
      }
    } else {
      setParentCategory(categoryId.toString());
    }
  }, [categoryId, categories]);

  const viewportRef = useRef<HTMLDivElement>(null);

  const [parentCategory, setParentCategory] = useState('0');

  if (!categories.length) return null;

  const handleSetTag = (categoryId: number | undefined) => {
    setCategoryId(categoryId ?? 0);
    if (redirectMode) {
      replace(
        {
          pathname,
          query: removeEmpty({ ...query, categoryId }),
        },
        undefined,
        {
          shallow: true,
        }
      );
    }
  };

  const _categoryId = selected ?? categoryId;
  const _setCategoryId = setSelected ?? handleSetTag;

  const onChangeParentCategory = (value: string) => {
    setParentCategory(value);
    _setCategoryId(parseInt(value));
    onChange && onChange(0);
  };

  const subCategorySelected = (active: boolean, categoryId: number) => {
    _setCategoryId(!active ? categoryId : undefined);
    onChange && onChange(categoryId);
  };

  return (
    <>
      <Box style={{ width: '100%', overflowX: 'auto' }}>
        <SegmentedControl
          radius="xl"
          fullWidth={false}
          size="sm"
          value={parentCategory}
          onChange={(value) => onChangeParentCategory(value)}
          data={categories
            .filter((item) => item.parentId === null)
            .map((item) => ({
              label: item.name,
              value: `${item.id}`,
            }))}
          style={{ textTransform: 'capitalize' }}
          classNames={classes}
        />
      </Box>
      <ScrollArea
        viewportRef={viewportRef}
        className={classes.tagsContainer}
        classNames={classes}
        type="never"
      >
        <Group
          className={classes.tagsGroup}
          spacing={6}
          style={{ backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : 'white' }}
        >
          {categories
            .filter((x) => (filter ? filter(x.name) : true))
            .filter((x) => x.parentId === parseInt(parentCategory))
            .map((tag) => {
              const active = _categoryId === tag.id;
              return (
                <Button
                  key={tag.id}
                  className={classes.tag}
                  variant={active ? 'filled' : theme.colorScheme === 'dark' ? 'filled' : 'light'}
                  color={active ? 'gold' : 'gray'}
                  onClick={() => subCategorySelected(active, tag.id)}
                  compact
                >
                  {removeTrailingNumber(tag.name)}
                </Button>
              );
            })}
        </Group>
      </ScrollArea>
    </>
  );
}
