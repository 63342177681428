import { Button, ButtonProps, createStyles, Footer, Group, Text } from '@mantine/core';
import { NextLink } from '@mantine/next';
import { useContainerSmallerThan } from '~/components/ContainerProvider/useContainerSmallerThan';
import { SocialLinks } from '~/components/SocialLinks/SocialLinks';
import { useFeatureFlags } from '~/providers/FeatureFlagsProvider';

const buttonProps: ButtonProps = {
  size: 'xs',
  variant: 'subtle',
  color: 'gray',
  px: 'xs',
};

export function AppFooter({ fixed = true }: { fixed?: boolean }) {
  const { classes, cx } = useStyles({ fixed });
  const mobile = useContainerSmallerThan('sm');
  const features = useFeatureFlags();

  return (
    <Footer className={cx(classes.root)} height="auto" p="sm" py={4}>
      <Group spacing={mobile ? 'sm' : 'lg'} sx={{ flexWrap: 'nowrap' }}>
        <Text
          weight={700}
          sx={{ whiteSpace: 'nowrap', userSelect: 'none' }}
        >
          &copy; LovedByMen {new Date().getFullYear()}
        </Text>
        <Group spacing={0} sx={{ flexWrap: 'nowrap' }}>
          {/*<Button*/}
          {/*  component={NextLink}*/}
          {/*  prefetch={false}*/}
          {/*  href="/content/careers"*/}
          {/*  {...buttonProps}*/}
          {/*  variant="subtle"*/}
          {/*  color="green"*/}
          {/*  px={mobile ? 5 : 'xs'}*/}
          {/*>*/}
          {/*  Join Us 💼*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  component={NextLink}*/}
          {/*  prefetch={false}*/}
          {/*  href="/advertise-with-us"*/}
          {/*  {...buttonProps}*/}
          {/*  variant="subtle"*/}
          {/*  color="yellow"*/}
          {/*  target="_blank"*/}
          {/*  rel="nofollow noreferrer"*/}
          {/*  px={mobile ? 5 : 'xs'}*/}
          {/*>*/}
          {/*  Advertise 📰*/}
          {/*</Button>*/}
          <Button
            component={NextLink}
            prefetch={false}
            href="/selfists-program"
            {...buttonProps}
            color="blue"
            px={mobile ? 5 : 'xs'}
          >
            Selfists Program
          </Button>
          <Button
            component={NextLink}
            prefetch={false}
            href="/content/tos"
            {...buttonProps}
            px={mobile ? 5 : 'xs'}
          >
            Terms of Service
          </Button>
          <Button
            component={NextLink}
            prefetch={false}
            href="/content/privacy"
            {...buttonProps}
            px={mobile ? 5 : 'xs'}
          >
            Privacy
          </Button>
          <Button component={NextLink} href="/safety" {...buttonProps}>
            Safety
          </Button>
          {features.newsroom && (
            <Button component={NextLink} href="/newsroom" {...buttonProps}>
              Newsroom
            </Button>
          )}
          {/*<Button*/}
          {/*  component="a"*/}
          {/*  href="https://status.lovedbymen.com"*/}
          {/*  {...buttonProps}*/}
          {/*  target="_blank"*/}
          {/*  rel="nofollow noreferrer"*/}
          {/*>*/}
          {/*  Status*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  component="a"*/}
          {/*  href="/wiki"*/}
          {/*  {...buttonProps}*/}
          {/*  target="_blank"*/}
          {/*  rel="nofollow noreferrer"*/}
          {/*>*/}
          {/*  Wiki*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  component="a"*/}
          {/*  href="/education"*/}
          {/*  {...buttonProps}*/}
          {/*  target="_blank"*/}
          {/*  rel="nofollow noreferrer"*/}
          {/*>*/}
          {/*  Education*/}
          {/*</Button>*/}
          {/*<Button*/}
          {/*  component="a"*/}
          {/*  href="https://air.lovedbymen.com"*/}
          {/*  {...buttonProps}*/}
          {/*  target="_blank"*/}
          {/*  rel="nofollow noreferrer"*/}
          {/*>*/}
          {/*  Residency*/}
          {/*</Button>*/}

          <SocialLinks />
        </Group>
        {/*<Group ml="auto" spacing={4} sx={{ flexWrap: 'nowrap' }}>*/}
        {/*  <Button*/}
        {/*    component="a"*/}
        {/*    href="/bugs"*/}
        {/*    {...buttonProps}*/}
        {/*    target="_blank"*/}
        {/*    pl={4}*/}
        {/*    pr="xs"*/}
        {/*    rel="nofollow noreferrer"*/}
        {/*  >*/}
        {/*    🪲 Bugs*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*    component="a"*/}
        {/*    href="/feedback"*/}
        {/*    variant="light"*/}
        {/*    color="yellow"*/}
        {/*    target="_blank"*/}
        {/*    size={!fixed ? 'xs' : undefined}*/}
        {/*    pl={4}*/}
        {/*    pr="xs"*/}
        {/*    rel="nofollow noreferrer"*/}
        {/*  >*/}
        {/*    💡 Feature Requests*/}
        {/*  </Button>*/}
        {/*</Group>*/}
      </Group>
    </Footer>
  );
}

const useStyles = createStyles((theme, args: { fixed: boolean }) => ({
  root: {
    position: args.fixed ? 'fixed' : undefined,
    bottom: 0,
    right: 0,
    zIndex: 100,
    left: 0,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    // boxShadow: '0 -1px 3px rgba(0, 0, 0, 0.05), 0 -1px 2px rgba(0, 0, 0, 0.1)',
    transitionProperty: 'transform',
    transitionDuration: '0.3s',
    transitionTimingFunction: 'linear',
    overflowX: 'auto',
    // transform: 'translateY(0)',
  },
  down: {
    transform: 'translateY(200%)',
    // bottom: '-60',
  },
}));
