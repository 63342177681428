import {
  MediaType,
  MetricTimeframe,
  ReviewReactions,
} from '@prisma/client';
import { z } from 'zod';
import { constants } from '~/server/common/constants';
import { periodModeSchema } from '~/server/schema/base.schema';
import { postgresSlugify } from '~/utils/string-helpers';
import { ImageSort } from './../common/enums';
import { SearchIndexEntityTypes } from '~/components/Search/parsers/base';
import { zc } from '~/utils/schema-helpers';

export type GetInfiniteImagesOutput = z.output<typeof getInfiniteImagesSchema>;

export const imageResourceUpsertSchema = z.object({
  id: z.number().optional(),
  name: z.string().optional(),
  detected: z.boolean().optional(),
});

export const imageSchema = z.object({
  id: z.number().optional(),
  name: z.string().nullish(),
  url: z
    .string()
    .url()
    .or(z.string().uuid('One of the files did not upload properly, please try again')),
  meta: z.preprocess((value) => {
    if (typeof value !== 'object') return null;
    if (value && !Object.keys(value).length) return null;
    return value;
  }, z.string().nullish()),
  hash: z.string().nullish(),
  height: z.number().nullish(),
  width: z.number().nullish(),
  needsReview: z.string().nullish(),
  mimeType: z.string().optional(),
  sizeKB: z.number().optional(),
  postId: z.number().nullish(),
  resources: z.array(imageResourceUpsertSchema).optional(),
  type: z.nativeEnum(MediaType).default(MediaType.image),
  metadata: z.object({}).passthrough().optional(),
});

export const comfylessImageSchema = imageSchema.extend({
  meta: z.any(),
});

export type ImageUploadProps = z.infer<typeof imageSchema>;

export const imageModerationSchema = z.object({
  ids: z.number().array(),
  needsReview: z.string().nullish(),
  reviewAction: z.enum(['delete', 'removeName', 'mistake']).optional(),
  reviewType: z.enum(['minor', 'poi', 'reported', 'csam', 'blocked']),
});
export type ImageModerationSchema = z.infer<typeof imageModerationSchema>;

export type UpdateImageInput = z.infer<typeof updateImageSchema>;
export const updateImageSchema = z.object({
  id: z.number(),
  meta: z.preprocess((value) => {
    if (typeof value !== 'object') return null;
    if (value && !Object.keys(value).length) return null;
    return value;
  }, z.string().optional()),
  hideMeta: z.boolean().optional(),
  resources: z.array(imageResourceUpsertSchema).optional(),
});

export type GetInfiniteImagesInput = z.infer<typeof getInfiniteImagesSchema>;

const imageInclude = z.enum([
  'tags',
  'count',
  'cosmetics',
  'report',
  'meta',
  'tagIds',
  'profilePictures',
]);

export const getInfiniteImagesSchema = z
  .object({
    limit: z.number().min(0).max(200).default(100),
    cursor: z.union([z.bigint(), z.number(), z.string()]).optional(),
    skip: z.number().optional(),
    postId: z.number().optional(),
    userId: z.number().optional(),
    categoryId: z.number().optional(),
    postIds: z.number().array().optional(),
    collectionId: z.number().optional(),
    modelId: z.number().optional(),
    imageId: z.number().optional(),
    reviewId: z.number().optional(),
    username: zc.usernameValidationSchema.optional(),
    prioritizedUserIds: z.array(z.number()).optional(),
    period: z.nativeEnum(MetricTimeframe).default(constants.galleryFilterDefaults.period),
    periodMode: periodModeSchema,
    sort: z.nativeEnum(ImageSort).default(constants.galleryFilterDefaults.sort),
    tags: z.array(z.number()).optional(),
    withTags: z.boolean().optional(),
    include: z.array(imageInclude).optional().default(['cosmetics']),
    excludeCrossPosts: z.boolean().optional(),
    reactions: z.array(z.nativeEnum(ReviewReactions)).optional(),
    ids: z.array(z.number()).optional(),
    types: z.array(z.nativeEnum(MediaType)).optional(),
    withMeta: z.boolean().optional(),
    hidden: z.boolean().optional(),
    followed: z.boolean().optional(),
    pending: z.boolean().optional(),
    notPublished: z.coerce.boolean().optional(),
  })
  .transform((value) => {
    if (value.withTags) {
      if (!value.include) value.include = [];
      value.include.push('tags');
    }
    if (value.withMeta) {
      if (!value.include) value.include = [];
      value.include.push('meta');
    }
    return value;
  });

export type GetImagesByCategoryInput = z.infer<typeof getImagesByCategorySchema>;
export const getImagesByCategorySchema = z.object({
  cursor: z.number().optional(),
  limit: z.number().min(1).max(30).optional(),
  imageLimit: z.number().min(1).max(30).optional(),
  sort: z.nativeEnum(ImageSort).optional(),
  period: z.nativeEnum(MetricTimeframe).optional(),
  periodMode: periodModeSchema,
  tags: z.number().array().optional(),
  username: z
    .string()
    .transform((data) => postgresSlugify(data))
    .nullish(),
  modelId: z.number().optional(),
});

export type GetImageInput = z.infer<typeof getImageSchema>;
export const getImageSchema = z.object({
  id: z.number(),
  withoutPost: z.boolean().optional(),
});
// #endregion

export type GetEntitiesCoverImage = z.infer<typeof getEntitiesCoverImage>;
export const getEntitiesCoverImage = z.object({
  entities: z.array(
    z.object({
      entityType: z.union([z.nativeEnum(SearchIndexEntityTypes), z.enum(['Model'])]),
      entityId: z.number(),
    }),
  ),
});

export type ImageReviewQueueInput = z.infer<typeof imageReviewQueueInputSchema>;
export const imageReviewQueueInputSchema = z.object({
  limit: z.number().min(0).max(200).default(100),
  cursor: z.union([z.bigint(), z.number()]).optional(),
  needsReview: z.string().nullish(),
  tagReview: z.boolean().optional(),
  reportReview: z.boolean().optional(),
  tagIds: z.array(z.number()).optional(),
});


export type ImageEntityType = (typeof imageEntities)[number];
const imageEntities = ['Bounty', 'BountyEntry', 'User', 'Post', 'Article'] as const;
const imageEntitiesSchema = z.enum(imageEntities);

export type CreateImageSchema = z.infer<typeof createImageSchema>;
export const createImageSchema = z.object({
  entityId: z.number().optional(),
  entityType: imageEntitiesSchema.optional(),
  id: z.number().optional(),
  name: z.string().nullish(),
  url: z.string().url().or(z.string().uuid()),
  hash: z.string().nullish(),
  height: z.number().nullish(),
  width: z.number().nullish(),
  postId: z.number().nullish(),
  modelId: z.number().optional(),
  index: z.number().optional(),
  mimeType: z.string().optional(),
  meta: z.preprocess((value) => {
    if (typeof value !== 'object') return null;
    if (value && !Object.keys(value).length) return null;
    return value;
  }, z.string().optional()),
  type: z.nativeEnum(MediaType).default(MediaType.image),
  metadata: z.object({}).passthrough().optional(),
});
