import { TablerIconsProps } from '@tabler/icons-react';

export function IconBrandHuggingFace({
  size = 24,
  color = 'currentColor',
  stroke = 2,
  ...props
}: TablerIconsProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-123"
      x="0px"
      y="0px"
      viewBox="0 0 95 88"
      xmlSpace="preserve"
      width={size}
      height={size}
      strokeWidth={stroke}
      stroke={color}
      fill={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path
        className="st0"
        d="M58.5,32.3c1.3,0.5,1.8,3.1,3.1,2.4c2.4-1.3,3.4-4.3,2.1-6.8c-1.3-2.4-4.3-3.4-6.8-2.1
 c-2.4,1.3-3.4,4.3-2.1,6.8C55.4,33.8,57.4,31.9,58.5,32.3z M34.9,32.3c-1.3,0.5-1.8,3.1-3.1,2.4c-2.4-1.3-3.4-4.3-2.1-6.8
 c1.3-2.4,4.3-3.4,6.8-2.1c2.4,1.3,3.4,4.3,2.1,6.8C38,33.8,36.1,31.9,34.9,32.3z M47,56.3c9.8,0,13-8.8,13-13.3
 c0-2.3-1.6-1.6-4.1-0.4c-2.3,1.2-5.5,2.7-8.9,2.7c-7.2,0-13-6.9-13-2.4S37.1,56.3,47,56.3z M17.5,48c-1.6,0-3.1,0.7-4.1,1.9
 c-0.6,0.7-1.3,2-1.3,3.8c-0.7-0.2-1.3-0.3-1.9-0.3c-1.6,0-3,0.6-3.9,1.7c-1.3,1.4-1.8,3.1-1.6,4.8C4.8,60.6,5,61.3,5.4,62
 c-0.9,0.7-1.5,1.7-1.8,2.8c-0.2,0.9-0.5,2.8,0.8,4.7c-0.1,0.1-0.2,0.3-0.2,0.4c-0.8,1.5-0.8,3.1-0.1,4.6c1,2.3,3.6,4.1,8.5,6.1
 c3.1,1.2,5.9,2,5.9,2c4.1,1.1,7.7,1.6,10.9,1.6c5.9,0,10-1.8,12.5-5.3c3.9-5.7,3.3-10.9-1.7-15.9c-2.8-2.8-4.6-6.9-5-7.8
 c-0.8-2.7-2.8-5.6-6.2-5.6h0c-0.3,0-0.6,0-0.9,0.1c-1.5,0.2-2.8,1.1-3.7,2.4c-1-1.3-2-2.2-2.9-2.8C20.2,48.4,18.8,48,17.5,48z
  M17.5,52c0.5,0,1.1,0.2,1.8,0.7c2.1,1.4,6.2,8.4,7.8,11.2c0.5,0.9,1.4,1.3,2.1,1.3c1.5,0,2.7-1.5,0.1-3.5c-3.9-2.9-2.5-7.7-0.7-8
 c0.1,0,0.2,0,0.2,0c1.7,0,2.4,2.9,2.4,2.9s2.2,5.5,6,9.3c3.8,3.8,4,6.8,1.2,10.8c-1.9,2.8-5.5,3.6-9.2,3.6c-3.8,0-7.7-0.9-9.9-1.5
 c-0.1,0-13.5-3.8-11.8-7c0.3-0.5,0.8-0.8,1.3-0.8c2.4,0,6.7,3.5,8.6,3.5c0.4,0,0.7-0.2,0.8-0.6c0.8-2.8-12.1-4-11-8.2
 c0.2-0.7,0.7-1,1.4-1c3.1,0,10.2,5.5,11.7,5.5c0.1,0,0.2,0,0.2-0.1c0.7-1.2,0.3-2-4.9-5.2c-5.2-3.2-8.9-5.1-6.8-7.3
 c0.2-0.3,0.6-0.4,1-0.4c3.2,0,10.7,6.8,10.7,6.8s2,2.1,3.2,2.1c0.3,0,0.5-0.1,0.7-0.4c0.9-1.5-8.1-8.2-8.6-11
 C15.9,53,16.5,52,17.5,52z M53.8,79.9c-2.1,0.4-4.3,0.6-6.6,0.6c-2.1,0-4.1-0.2-6.1-0.5c0.3-0.3,0.6-0.7,0.8-1.1
 c0.6-0.9,1.1-1.8,1.5-2.7c1.3,0.1,2.5,0.2,3.8,0.2c1.5,0,2.9-0.1,4.3-0.3c0.4,0.9,0.9,1.8,1.5,2.7C53.3,79.3,53.5,79.6,53.8,79.9z
  M47.2,7C66.4,7,82,22.6,82,41.8c0,2.7-0.3,5.2-0.9,7.7c0.1,0.1,0.3,0.3,0.4,0.4c0.6,0.7,1.3,2,1.3,3.8c0.5-0.1,0.9-0.2,1.4-0.3
 c1.2-3.7,1.8-7.6,1.8-11.6C86,20.3,68.6,3,47.2,3C25.8,3,8.5,20.3,8.5,41.8c0,4,0.6,7.9,1.8,11.6c0.6,0,1.2,0.1,1.9,0.3
 c0.1-1.8,0.7-3,1.3-3.7c-0.6-2.6-1-5.3-1-8.1C12.5,22.6,28,7,47.2,7z M77.4,48c1.6,0,3.1,0.7,4.1,1.9c0.6,0.7,1.3,2,1.3,3.8
 c0.7-0.2,1.3-0.3,1.9-0.3c1.5,0,2.9,0.6,3.9,1.7c1.3,1.4,1.8,3.1,1.6,4.8c-0.1,0.8-0.4,1.5-0.8,2.2c0.9,0.7,1.5,1.7,1.8,2.8
 c0.2,0.9,0.5,2.8-0.8,4.7c0.1,0.1,0.2,0.3,0.2,0.4c0.8,1.5,0.8,3.1,0.1,4.6c-1,2.3-3.6,4.1-8.5,6.1c-3.1,1.2-5.9,2-5.9,2
 c-4.1,1.1-7.7,1.6-10.9,1.6c-5.9,0-10-1.8-12.5-5.3C49.1,73.2,49.7,68,54.7,63c2.8-2.8,4.6-6.9,5-7.8c0.8-2.7,2.8-5.6,6.2-5.6h0
 c0.3,0,0.6,0,0.9,0.1c1.5,0.2,2.8,1.1,3.7,2.4c1-1.3,2-2.2,2.9-2.8C74.8,48.4,76.1,48,77.4,48z M77.4,52c-0.5,0-1.1,0.2-1.8,0.7
 c-2.1,1.4-6.2,8.4-7.8,11.2c-0.5,0.9-1.4,1.3-2.1,1.3c-1.5,0-2.7-1.5-0.1-3.5c3.9-2.9,2.5-7.7,0.7-8c-0.1,0-0.2,0-0.2,0
 c-1.7,0-2.5,2.9-2.5,2.9s-2.2,5.5-6,9.3c-3.8,3.8-4,6.8-1.2,10.8c1.9,2.8,5.5,3.6,9.2,3.6c3.8,0,7.7-0.9,9.9-1.5
 c0.1,0,13.5-3.8,11.8-7c-0.3-0.5-0.8-0.8-1.3-0.8c-2.4,0-6.7,3.5-8.6,3.5c-0.4,0-0.7-0.2-0.8-0.6c-0.8-2.8,12.1-4,11-8.2
 c-0.2-0.7-0.7-1-1.4-1c-3.1,0-10.2,5.5-11.7,5.5c-0.1,0-0.2,0-0.2-0.1c-0.7-1.2-0.3-2,4.9-5.2c5.2-3.2,8.9-5.1,6.8-7.3
 c-0.2-0.3-0.6-0.4-1-0.4c-3.2,0-10.7,6.8-10.7,6.8s-2,2.1-3.2,2.1c-0.3,0-0.5-0.1-0.7-0.4c-0.9-1.5,8.1-8.2,8.6-11
 C79.1,53,78.5,52,77.4,52z"
      />
    </svg>
  );
}
